import { DEFAULT_THEMATICS_COLOR } from "../constants/Constants";

// creating dropdown options from the thematics, calculating the depth level
// to handle thematic nesting
export const thematicOptions = (thematics, coloredThematics, toggledThematicIds): any => {
  return (thematics || [])
    .map((t) => {
      const show = !t.parent_thematic_id || toggledThematicIds.includes(t.parent_thematic_id);
      return optionFromThematic(thematics, coloredThematics, t, show);
    });
};

const optionFromThematic = (thematics, coloredThematics, { _id, localized_name, color, depth_level, has_children }, show): any => {

  return {
    value: _id,
    label: localized_name,
    color: coloredThematics ? color : DEFAULT_THEMATICS_COLOR,
    depthLevel: depth_level,
    hasChildren: has_children && thematicWithParentPresent(thematics, _id),
    show
  };
};

// thematics may have sub-thematics but sub-thematics may be filtered out (i.e: not be in props),
// for example on the registration form it's possible to only propose a subset of thematics
const thematicWithParentPresent = (thematics, id): void => {
  return thematics.find(t => t.parent_thematic_id === id);
};

export const toggleThematicHandler = (thematics, thematicId, toggledThematicIds, setToggledThematicIds: (values: any) => void): any => {
  return (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (toggledThematicIds.includes(thematicId)) {
      // should remove the thematic and its children
      const thematic = findThematic(thematics, thematicId);
      const childrenIds = thematicChildrenIds(thematics, thematic);
      const toRemove = [...childrenIds, thematicId];

      setToggledThematicIds([ ...toggledThematicIds.filter(id => !toRemove.includes(id)) ]);
    } else {
      setToggledThematicIds([ ...toggledThematicIds, thematicId ]);
    }
  };
};

const findThematic = (thematics, id): void => {
  return thematics.find(t => t._id === id);
};

// return the ids of the children of the thematic
export const thematicChildrenIds = (thematics, thematic): any => {
  const children = thematics.filter(t => t.parent_thematic_id === thematic._id);
  return children.flatMap(t => [t._id].concat(thematicChildrenIds(thematics, t)));
};
